import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC, MouseEvent, useState } from 'react';
import { useToasts } from '~/context/ToastContext';
import { useUser } from '~/context/UserContext';
import ChevronDown from '~/icons/ChevronDown';
import Star from '~/icons/Star';
import { capitalizeText } from '~/utils/helpers';

import { NavLink, useRevalidator } from '@remix-run/react';

import styles from './Sidebar.module.scss';

interface Props {
  tags: {
    title: string;
    slug: string;
    id: number;
  }[];
  setShowLogInModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuggestedTopics: FC<Props> = ({ tags, setShowLogInModal }) => {
  const { createToast } = useToasts();
  const { user } = useUser();

  const [showAll, setShowAll] = useState(true);
  const { revalidate } = useRevalidator();

  const onButtonClick = async (
    e: MouseEvent<HTMLButtonElement>,
    tagId: number,
    tagTitle: string,
  ) => {
    e.stopPropagation();
    e.preventDefault();

    const capitalizedTitle = capitalizeText(tagTitle);
    if (!user) {
      return setShowLogInModal(true);
    }
    const response = await fetch(`/toggle-tag/${tagId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    await response.json();
    createToast('success', `${capitalizedTitle} added to your Followed Topics`);
    revalidate();
  };

  return (
    <div className={styles.tagsBlock}>
      <button
        className={classNames(styles.toggleButton, {
          [styles.toggleButtonActive]: showAll,
        })}
        onClick={() => setShowAll((prev) => !prev)}
      >
        Suggested Topics <ChevronDown />
      </button>
      <motion.div
        initial={{ height: 'auto' }}
        animate={{ height: showAll ? 'auto' : 0 }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
        className={styles.hiddenTags}
      >
        <div
          className={classNames(
            styles.linksBlockInner,
            styles.linksBlockInnerTopics,
          )}
        >
          {tags.map((tag) => (
            <NavLink
              key={tag.id}
              to={`/discover/${tag.slug}`}
              className={({ isActive }) =>
                classNames(styles.tagLink, { [styles.linkActive]: isActive })
              }
            >
              <span title={tag.title}>{tag.title}</span>
              <button
                className={styles.toggleTagButton}
                aria-label='Save/Unsave Tag'
                onClick={(e) => onButtonClick(e, tag.id, tag.title)}
              >
                <Star />
              </button>
            </NavLink>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default SuggestedTopics;
