import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';
import { useUser } from '~/context/UserContext';
import useOnClickOutside from '~/hooks/useOnClickOutside';
import { User } from '~/types';

import { useLocation } from '@remix-run/react';

import styles from './HeaderUser.module.scss';
import UserDropdown from './UserDropdown';
import UserModal from './UserModal';

interface Props {
  user: User;
  closeMenu: () => void;
}

const HeaderUser: FC<Props> = ({ user, closeMenu }) => {
  const { setUser } = useUser();
  const [showDropdown, setShowDropdown] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(true);
  const location = useLocation();

  useOnClickOutside(componentRef, () => setShowDropdown(false));

  useEffect(() => {
    setShowDropdown(false);
  }, [location]);

  useEffect(() => {
    const onWindowResize = () => {
      if (window.innerWidth > 767) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };

    onWindowResize();

    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  });

  const onLogout = async () => {
    const response = await fetch(`/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    await response.json();

    setUser(null);
    window.location.href = '/';
  };

  return (
    <div ref={componentRef} className={styles.holder}>
      <button
        onClick={() => {
          closeMenu();
          setShowDropdown((prev) => !prev);
        }}
        className={styles.userButton}
      >
        {user.avatar?.small ? (
          <img
            loading='lazy'
            src={user.avatar.small}
            className={styles.image}
            alt={user.name}
          />
        ) : (
          <div className={styles.image}></div>
        )}
      </button>
      <AnimatePresence>
        {showDropdown &&
          (isMobile ? (
            <UserModal onLogout={onLogout} />
          ) : (
            <UserDropdown onLogout={onLogout} />
          ))}
      </AnimatePresence>
    </div>
  );
};

export default HeaderUser;
