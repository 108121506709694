import layoutSyles from 'app/scss/layout/layout.module.scss';
import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { useUser } from '~/context/UserContext';
import Burger from '~/icons/Burger';
import Close from '~/icons/Close';
import Logo from '~/icons/Logo';

import { Link } from '@remix-run/react';

import LogInModal from '../LogInModal/LogInModal';
import Overlay from '../Overlay/Overlay';
import styles from './Header.module.scss';
import HeaderUser from './HeaderUser';

interface Props {
  setIsMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isMenuOpened: boolean;
}

const Header: FC<Props> = ({ setIsMenuOpened, isMenuOpened }) => {
  const { user } = useUser();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const onWindowResize = () => {
      if (window.innerWidth > 1247) {
        setIsMenuOpened(false);
      }
    };

    onWindowResize();

    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  });

  return (
    <header className={styles.header}>
      <div className={layoutSyles.container}>
        <div className={styles.headerInner}>
          <div className={styles.headerLeft}>
            <button
              className={styles.menuButton}
              onClick={() => setIsMenuOpened((prev) => !prev)}
              aria-label='Toggle navigation'
            >
              {isMenuOpened ? <Close /> : <Burger />}
            </button>
            <Link to='/' className={styles.logo} aria-label='Go to Homepage'>
              <Logo />
            </Link>
          </div>
          <div className={styles.buttons}>
            {!user ? (
              <button
                className={styles.logIn}
                onClick={() => {
                  setIsMenuOpened(false);
                  setShowModal(true);
                }}
              >
                Log in
              </button>
            ) : (
              <HeaderUser
                closeMenu={() => setIsMenuOpened(false)}
                user={user}
              />
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isMenuOpened && <Overlay />}
        {showModal && <LogInModal onClose={() => setShowModal(false)} />}
      </AnimatePresence>
    </header>
  );
};

export default Header;
