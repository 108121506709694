import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC, MouseEvent, useState } from 'react';
import { useToasts } from '~/context/ToastContext';
import { useUser } from '~/context/UserContext';
import ChevronDown from '~/icons/ChevronDown';
import FullStar from '~/icons/FullStar';
import Plus from '~/icons/Plus';
import { capitalizeText } from '~/utils/helpers';

import { NavLink, useRevalidator } from '@remix-run/react';

import styles from './Sidebar.module.scss';

interface Props {
  tags?: {
    title: string;
    slug: string;
    id: number;
  }[];
  setShowLogInModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FollowedTopics: FC<Props> = ({ tags, setShowLogInModal }) => {
  const { user } = useUser();
  const { createToast } = useToasts();

  const [showAll, setShowAll] = useState(true);
  const { revalidate } = useRevalidator();

  const onButtonClick = async (
    e: MouseEvent<HTMLButtonElement>,
    tagId: number,
    tagTitle: string,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    const capitalizedTitle = capitalizeText(tagTitle);
    const response = await fetch(`/toggle-tag/${tagId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    await response.json();
    createToast(
      'success',
      `${capitalizedTitle} removed from your Followed Topics`,
    );
    revalidate();
  };

  return (
    <div className={styles.linksBlock}>
      <button
        className={classNames(styles.toggleButton, {
          [styles.toggleButtonActive]: showAll,
        })}
        onClick={() => setShowAll((prev) => !prev)}
      >
        Followed Topics <ChevronDown />
      </button>
      <motion.div
        initial={{ height: 'auto' }}
        animate={{ height: showAll ? 'auto' : 0 }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
        className={styles.hiddenTags}
      >
        <div
          className={classNames(
            styles.linksBlockInner,
            styles.linksBlockInnerTopics,
          )}
        >
          {tags?.length ? (
            <>
              {tags.map((tag) => (
                <NavLink
                  key={tag.id}
                  to={`/discover/${tag.slug}`}
                  className={({ isActive }) =>
                    classNames(styles.tagLink, {
                      [styles.linkActive]: isActive,
                    })
                  }
                >
                  <span title={tag.title}>{tag.title}</span>
                  <button
                    className={styles.toggleTagButton}
                    aria-label='Save/Unsave Tag'
                    onClick={(e) => onButtonClick(e, tag.id, tag.title)}
                  >
                    <FullStar />
                  </button>
                </NavLink>
              ))}
            </>
          ) : (
            <>
              {user ? (
                <NavLink
                  to={`/user-profile/categories`}
                  className={({ isActive }) =>
                    classNames(styles.tagLink, {
                      [styles.linkActive]: isActive,
                    })
                  }
                >
                  Add topic
                  <Plus />
                </NavLink>
              ) : (
                <button
                  onClick={() => setShowLogInModal(true)}
                  className={styles.tagLink}
                >
                  Add topic
                  <Plus />
                </button>
              )}
            </>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default FollowedTopics;
